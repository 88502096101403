import React, { useContext, useState } from "react";
import { instance, setFormToken, setToken } from "../../app/AxiosConfig";
import { setCookie } from "../../app/CookieConfig";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../components/context/AuthContext";

const Login = () => {
  // ... (previous state and functions remain the same)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const { setIsLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors(validateForm({ ...formData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus("loading");
    setApiError("");
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        const { data } = await instance.post("/users/login", formData);
        if (data) {
          localStorage.setItem("cid", data.cid);
        }
        const accessTokenExpireAt = Date.parse(data.access_token_expires_at);
        const refreshTokenExpireAt = Date.parse(data.refresh_token_expires_at);
        const currentMilis = Date.now();

        setCookie(
          "refresh_token",
          data.refresh_token,
          parseInt((refreshTokenExpireAt - currentMilis) / 1000)
        );
        setCookie(
          "access_token",
          data.access_token,
          parseInt((accessTokenExpireAt - currentMilis) / 1000)
        );
        setFormToken("access_token");
        setToken("access_token");
        setIsLogin(true);
        navigate("/");
        setSubmitStatus("success");
      } catch (error) {
        console.error(error);
        setSubmitStatus("error");
        if (error.response) {
          setApiError(
            error.response.data.message || "An error occurred during login."
          );
        } else if (error.request) {
          setApiError(
            "No response received from the server. Please try again later."
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          setApiError("An error occurred. Please try again.");
        }
      }
    } else {
      setSubmitStatus("error");
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    if (!data.password) {
      errors.password = "Password is required";
    } else if (data.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    return errors;
  };

  const getSubmitButtonClass = () => {
    const baseClasses =
      "w-full bg-darkblue text-white py-2 px-4 rounded-md focus:ring-darkblue focus:ring-offset-2 transition duration-150 ease-in-out";
    switch (submitStatus) {
      case "loading":
        return `${baseClasses} opacity-75`;
      case "success":
        return `${baseClasses} bg-green-500 hover:bg-green-600`;
      case "error":
        return `${baseClasses} bg-red-500 hover:bg-red-600`;
      default:
        return `${baseClasses} hover:bg-opacity-90`;
    }
  };

  const getSubmitButtonContent = () => {
    switch (submitStatus) {
      case "loading":
        return (
          <div className="flex items-center justify-center">
            <svg
              className="animate-spin h-5 w-5 text-white mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Logging in...
          </div>
        );
      case "success":
        return "Login Successful";
      case "error":
        return "Error! Try Again";
      default:
        return "Log In";
    }
  };

  return (
    <>
      <div className="flex justify-center py-10">
        <div className="bg-white p-8 rounded-md shadow-md w-full max-w-sm">
          <div className="flex justify-center mb-6">
            <img
              src={"/images/logo/inflectionORG.webp"}
              alt="Logo"
              className="h-16 w-16"
            />
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
          {apiError && (
            <div className="mb-4 p-2  border border-red text-red rounded">
              {apiError}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {/* ... (email and password input fields remain the same) */}
            <div className="mb-4">
              <label className="block text-gray mb-2" htmlFor="email">
                Email
              </label>
              <input
                onChange={handleChange}
                type="text"
                name="email"
                className={`w-full px-3 py-2 border ${
                  errors.email ? "border-red" : "border-gray"
                } rounded-md focus:outline-none focus:ring-1 ${
                  errors.email ? "focus:ring-red" : "focus:ring-blue"
                }`}
                placeholder="Enter your email"
                value={formData.email}
              />
              {errors.email && (
                <p className="text-red text-sm mt-1">{errors.email}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray mb-2" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                name="password"
                className={`w-full px-3 py-2 border ${
                  errors.password ? "border-red" : "border-gray"
                } rounded-md focus:outline-none focus:ring-1 ${
                  errors.password ? "focus:ring-red" : "focus:ring-blue"
                }`}
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && (
                <p className="text-red text-sm mt-1">{errors.password}</p>
              )}
            </div>
            <button
              type="submit"
              disabled={submitStatus === "loading"}
              className={`${getSubmitButtonClass()} flex items-center justify-center`}
            >
              {getSubmitButtonContent()}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
