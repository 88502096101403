import axios from 'axios';
import { getCookie } from './CookieConfig';

export const baseURL = process.env.REACT_APP_API_URL;

let instance = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
  },
});

const formInstance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
  timeout: 30000,
});
function setFormToken(token) {
  formInstance.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
    token
  )}`;
}
function setToken(token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
    token
  )}`;
}

export { instance, formInstance, setToken, setFormToken };
