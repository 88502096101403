import axios from "axios";
import { getCookie } from "../api/cookies";

export const baseURL = process.env.REACT_APP_API_URL;

// export const siteBaseURL = process.env.REACT_APP_SITE_URL;

let apiClient = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 30000,
  headers: {
    authorization: `Bearer ${getCookie("access_token")}`,
    Accept: "application/json",
    "Content-Type": "application/json",
    cid: `${localStorage.getItem("cid")}`,
  },
});

const setAuthHeader = (tokenName) => {
  apiClient = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      authorization: `Bearer ${getCookie(tokenName)}`,
      cid: `${localStorage.getItem("cid")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
// ---------------- Form Data axios configurations -----------
let formDataApiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getCookie("access_token")}`,
    cid: `${localStorage.getItem("cid")}`,
  },
  timeout: 60000,
});

const setFormDataAuthHeader = () => {
  formDataApiClient = axios.create({
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getCookie("access_token")}`,
      cid: `${localStorage.getItem("cid")}`,
    },
    timeout: 60000,
  });
};

// ------------------ User -----------------------

const getAllUsers = () => {
  return apiClient.get(`/users/list`);
};

const signUp = (payload) => {
  return apiClient.post("/users/signup", payload);
};
const login = (payload) => {
  return apiClient.post("/users/login", payload);
};
const resetPassword = (token, payload) => {
  return apiClient.patch(`/users/reset_password/${token}`, payload);
};
const forgotPassword = (payload) => {
  return apiClient.post("/users/forgot_password", payload);
};

const renewAccessToken = () => {
  return apiClient.get("/users/renew-token");
};

const getMyProfile = () => {
  return apiClient.get("/users/profiles/my");
};

const logOut = () => {
  localStorage.removeItem("cid");
  return apiClient.delete("/users/logout");
};

// ------------------ Video -----------------------
const addVideo = (payload) => {
  return formDataApiClient.post("/posts/video/create", payload);
};
const getAllVideos = (id) => {
  return apiClient.get("/posts/video/list");
};
const deleteVideo = (id) => {
  return apiClient.delete(`/posts/video/${id}`);
};

// ------------------ Category -----------------------
const getAllCategories = () => {
  return apiClient.get("/categories/list");
};
const deleteCategory = (id) => {
  return apiClient.delete(`/categories/delete/${id}`);
};
const createCategory = (payload) => {
  return apiClient.post("/categories/create", payload);
};

// ------------------ Tags -----------------------
const getAllTags = () => {
  return apiClient.get("/tags/list");
};
const addTagsToBlog = (payload) => {
  return apiClient.post("/post_tags/create", payload);
};

const getAllTagsByBlog = () => {
  return apiClient.get("/post_tags/list");
};

const deleteTagByBlog = (id) => {
  return apiClient.delete(`/post_tags/delete/${id}`);
};

const deleteTag = (id) => {
  return apiClient.delete(`/tags/delete/${id}`);
};
const createTags = (payload) => {
  return apiClient.post("/tags/create", payload);
};
const updateTag = (id, payload) => {
  return apiClient.patch(`/tags/update/${id}`, payload);
};

// ------------------ Blogs -----------------------
const createBlog = (payload) => {
  return formDataApiClient.post("/posts/create", payload);
};

const updateBlog = (id, payload) => {
  return formDataApiClient.post(`/posts/update/${id}`, payload);
};

const PublishBlog = (id, payload) => {
  return formDataApiClient.patch(`/posts/update/${id}`, payload);
};

const getAllBlogs = (limit = 1000, page = 1) => {
  return apiClient.get(`/posts/list?limit=${limit}&page=${page}`);
};

const getAllBlogsByCategoryId = (id) => {
  return apiClient.get(`/posts/category/${id}`);
};

const deleteABlog = (id) => {
  return apiClient.get(`/gallery/delete/${id}`);
};
const deleteBlog = (id) => {
  return apiClient.delete(`/posts/delete/${id}`);
};

// ------------------ Comments -----------------------

const getResentComment = () => {
  return apiClient.get("/comments/all/letest");
};

// ------------------ Contacts -----------------------

const getContacts = () => {
  return apiClient.get("/posts/contact_us/list");
};

// ------------------ Gallery -----------------------
const addImageToGallery = (payload) => {
  return formDataApiClient.post("/gallery/create", payload);
};

const getAllImages = () => {
  return apiClient.get("/gallery/list");
};

const deleteGalleryImage = (id) => {
  return apiClient.delete(`/gallery/delete/${id}`);
};

// ------------------ FAQ -----------------------
const addFaq = (payload) => {
  return apiClient.post("/posts/faq/create", payload);
};

const getFaq = () => {
  return apiClient.get("/posts/faq/list");
};

const editFaq = (id, payload) => {
  return apiClient.patch(`/posts/faq/update/${id}`, payload);
};
const deleteFaq = (id) => {
  return apiClient.delete(`/posts/faq/${id}`);
};

export {
  setAuthHeader,
  signUp,
  login,
  getMyProfile,
  logOut,
  setFormDataAuthHeader,
  formDataApiClient,
  getAllUsers,
  renewAccessToken,
  forgotPassword,
  resetPassword,
  // FAQ
  addFaq,
  getFaq,
  editFaq,
  deleteFaq,
  // Gallery
  addImageToGallery,
  getAllImages,
  deleteGalleryImage,
  // Comments
  getResentComment,
  // Contacts
  getContacts,
  // Videos
  getAllVideos,
  addVideo,
  deleteVideo,
  // Blogs
  createBlog,
  updateBlog,
  PublishBlog,
  getAllBlogs,
  getAllBlogsByCategoryId,
  deleteABlog,
  deleteBlog,
  // Category
  getAllCategories,
  createCategory,
  deleteCategory,
  // Tags
  getAllTags,
  createTags,
  updateTag,
  deleteTag,
  getAllTagsByBlog,
  deleteTagByBlog,
  addTagsToBlog,
  // Forms
};
