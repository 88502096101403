import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import imageCompression from "browser-image-compression";
import { FaCloudUploadAlt } from "react-icons/fa";
import GetCroppedImg from "./GetCroppedImg";
import Loading from "../../loading/Loading";

const aspectRatios = [
  { label: "4:3", value: 4 / 3 },
  { label: "16:9", value: 16 / 9 },
  { label: "1:1", value: 1 },
  { label: "5:4", value: 5 / 4 },
  { label: "3:2", value: 3 / 2 },
  { label: "5:3", value: 5 / 3 },
  { label: "9:16", value: 9 / 16 },
];

export default function ImageUpload({
  onImageUpload,
  fixedAspectRatio = false,
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [selectedAspect, setSelectedAspect] = useState(aspectRatios[6].value); // Default to 9:16
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    try {
      const croppedImage = await GetCroppedImg(imageSrc, croppedArea);
      const compressedImage = await imageCompression(croppedImage, {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
      });
      const imageUrl = URL.createObjectURL(compressedImage);
      onImageUpload(compressedImage, imageUrl);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [imageSrc, croppedArea, onImageUpload]);

  return (
    <>
      {loading && <Loading />}
      <div className="flex flex-col items-center p-4">
        <div
          {...getRootProps()}
          className="rounded-full border border-olivegreen shadow-sm cursor-pointer p-4"
        >
          <input {...getInputProps()} />
          <FaCloudUploadAlt size={40} />
        </div>
        <h2>Upload New Images</h2>
        {imageSrc && (
          <div className="fixed inset-0 bg-black/70 z-50 flex justify-center items-center">
            <div>
              <div className="relative w-[800px] h-96 bg-white text-center rounded-md px-4 py-2">
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={fixedAspectRatio ? 16 / 9 : selectedAspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              {!fixedAspectRatio && (
                <div className="mt-4 flex space-x-4 bg-olivegreen px-3 py-1 rounded-sm">
                  {aspectRatios.map((ratio) => (
                    <label key={ratio.label} className="flex items-center">
                      <input
                        type="radio"
                        value={ratio.value}
                        checked={selectedAspect === ratio.value}
                        onChange={() => setSelectedAspect(ratio.value)}
                        className="mr-2"
                      />
                      {ratio.label}
                    </label>
                  ))}
                </div>
              )}
              <div className="flex justify-center items-center">
                <button
                  onClick={showCroppedImage}
                  className="mt-4 p-2 bg-darkblue text-white"
                >
                  Crop & Compress
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
