import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import * as api from "../../components/utils/api/apiClient";
import FaqForm from "../../components/forms/FaqForm.jsx";
import { CiFilter } from "react-icons/ci";
import FaqEditPopup from "../../components/popup/FaqEditPopup.jsx";

function Faq() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [editFaq, setEditFaq] = useState({
    show: false,
    data: {},
  });

  useEffect(() => {
    const getFaqs = async () => {
      setLoading(true);
      try {
        const { data } = await api.getFaq();
        if (data) {
          setData(data?.faqs || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getFaqs();
  }, [pageRefresh]);

  const deleteFaq = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.deleteFaq(id);
      if (data) {
        setPageRefresh(!pageRefresh);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Layout>
      <div>
        {loading && <Loading />}
        {editFaq.show && (
          <FaqEditPopup
            isOpen={editFaq.show}
            handleClose={() => setEditFaq({ show: false, data: {} })}
            data={editFaq.data}
            pageRefresh={() => setPageRefresh(!pageRefresh)}
          />
        )}
        <FaqForm pageRefresh={() => setPageRefresh(!pageRefresh)} />
        <div className="my-5">
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-semibold pb-3">All Questions</h1>
            <h4 className="text-lg font-semibold pb-3 flex gap-1 items-center">
              <CiFilter />
              Filters
            </h4>
          </div>
          <div className="flex flex-col gap-5">
            {data.length > 0 &&
              data?.map((item) => (
                <div
                  key={item.id}
                  className=" flex justify-between items-center border-2 border-lightgreen bg-darkblue bg-opacity-10 p-3 rounded"
                >
                  <ul>
                    <li className="font-semibold">{`Question: ${item.question}`}</li>
                    <li className="font-semibold">
                      {`Answer: `}
                      <span className="text-sm font-normal">{item.answer}</span>
                    </li>
                  </ul>
                  <div className="flex gap-5 items-center">
                    <button
                      className="text-darkblue"
                      onClick={() => {
                        setEditFaq({
                          show: true,
                          data: item,
                        });
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="text-red"
                      onClick={() => deleteFaq(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Faq;
