import React, { useEffect, useState } from "react";
import ImageUpload from "../../components/utils/image/ImageUpload";
import GalleryImage from "../../components/utils/gallery/GalleryImage";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import {
  addImageToGallery,
  getAllImages,
} from "../../components/utils/api/apiClient";

const Gallery = () => {
  const [croppedImage, setCroppedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [fetchedImages, setFetchedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (cropped, show) => {
    setCroppedImage(cropped);
    setShowImage(show);
  };

  const saveImage = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("image_url", croppedImage);
    try {
      const res = await addImageToGallery(data);
      if (res) {
        setLoading(false);
        setShowImage(null);
        fetchImages();
      }
    } catch (error) {
      setLoading(false);
      console.log("ImageUploadErr", error);
    }
  };

  const fetchImages = async () => {
    setLoading(true);
    try {
      const res = await getAllImages();
      setFetchedImages(res?.data || []);
      setLoading(false);
    } catch (error) {
      console.log("fetchImageErr", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <Layout>
      {loading && <Loading />}
      <div>
        {showImage ? (
          <div className="space-y-4">
            <div className="flex justify-center">
              <img src={showImage} alt="" width={500} height={500} />
            </div>
            <div onClick={saveImage} className="flex justify-center">
              <button className="bg-blue px-8 py-2 rounded-sm">SAVE</button>
            </div>
          </div>
        ) : (
          <div>
            <ImageUpload
              onImageUpload={handleImageUpload}
              fixedAspectRatio={false}
            />
            {/* Galley Image Showing with preview and delete feature in this component */}
            <div>
              <GalleryImage
                fetchedImages={fetchedImages}
                fetchImages={fetchImages}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Gallery;
