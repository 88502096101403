import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import FaqForm from "../forms/FaqForm";
import { MdOutlineClose } from "react-icons/md";

function FaqEditPopup({ isOpen, handleClose, pageRefresh, data }) {
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Edit FAQ
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <MdOutlineClose />
        </IconButton>
        <DialogContent dividers>
          <FaqForm
            pageRefresh={() => {
              pageRefresh();
              handleClose();
            }}
            data={data}
            whatDoYouWant="edit"
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default FaqEditPopup;
