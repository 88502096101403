import React from "react";
import { Link } from "react-router-dom";

function ResentCard({ title, link = "#", children }) {
  return (
    <div className="border border-lightgreen w-full rounded">
      <div>
        <div className="p-3 border-b border-lightgreen flex items-center justify-between">
          <h1 className="text-lg font-semibold">{title}</h1>
          <Link to={link} className="text-sm text-darkblue">
            View all
          </Link>
        </div>
      </div>
      <div className="w-full rounded">{children}</div>
    </div>
  );
}

export default ResentCard;
