import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import YoutubeVideoCard from "../../components/YoutubevideoCard";
import Loading from "../../components/loading/Loading";
import { MdDelete, MdEdit } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import SubmitButton from "../../components/buttons/SubmitButton";
import {
  addVideo,
  deleteVideo,
  getAllVideos,
} from "../../components/utils/api/apiClient";

function Videos() {
  const [formData, setFormData] = useState({
    thumbnail_url: {},
    video_url: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState();

  useEffect(() => {
    const getVideos = async () => {
      setLoading(true);
      try {
        const { data } = await getAllVideos();
        if (data) {
          setData(data?.video || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getVideos();
  }, [pageRefresh]);

  const addVideoHandelSubmit = async () => {
    setLoading(true);
    try {
      const videoFormData = new FormData();
      videoFormData.append("thumbnail_url", formData.thumbnail_url);
      videoFormData.append("video_url", formData.video_url);
      const { data } = await addVideo(videoFormData);
      if (data) {
        setFormData({
          thumbnail_url: {},
          video_url: "",
        });
        setPageRefresh(!pageRefresh);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const deleteVideoHandel = async (id) => {
    setLoading(true);
    try {
      const { data } = await deleteVideo(id);
      if (data) {
        setPageRefresh(!pageRefresh);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  function playVideo(video) {
    setIsOpen(true);
    setCurrentVideo(video);
  }
  function close() {
    setIsOpen(false);
  }

  return (
    <Layout>
      {loading && <Loading />}
      <div>
        {/* Videos create section */}
        <div>
          <div className="mb-4">
            <label className="block mb-2">Question</label>
            <input
              required
              type="file"
              // value={formData?.thumbnail_url}
              className="w-full p-2 border border-border rounded-lg"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  thumbnail_url: e.target.files[0],
                });
              }}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Video URL</label>
            <input
              required
              type="text"
              value={formData?.video_url}
              className="w-full p-2 border border-border rounded-lg"
              onChange={(e) =>
                setFormData({ ...formData, video_url: e.target.value })
              }
            />
          </div>
          {/* <div className="mb-4">
            <label className="block mb-2">Name</label>
            <input
              required
              type="text"
              value={formData?.name}
              className="w-full p-2 border border-border rounded-lg"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Description</label>
            <input
              required
              type="text"
              value={formData?.description}
              className="w-full p-2 border border-border rounded-lg"
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
          </div> */}
          {/* <button
            disabled={formData.video_url?.length > 0 ? false : true}
            onClick={addVideoHandelSubmit}
            className="w-full md:w-auto bg-darkblue text-white rounded px-8 py-3"
          >
            Submit
          </button> */}
          <SubmitButton
            functions={addVideoHandelSubmit}
            disabled={formData.video_url?.length > 0 ? false : true}
            name="Submit"
          />
        </div>
        {/* Videos Showing section */}
        <div className="border border-lightgreen rounded my-3">
          <div className="flex justify-between items-center p-3 border-b border-lightgreen">
            <h1 className="text-xl font-semibold">All Videos</h1>
            <h4 className="text-lg font-semibold flex gap-1 items-center">
              <CiFilter />
              Filters
            </h4>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-3">
            {data.length > 0 &&
              data?.map((element) => {
                return (
                  <>
                    <div className="rounded">
                      <div
                        key={element?.id}
                        className="relative aspect-w-16 aspect-h-9 rounded"
                      >
                        <div className="flex relative justify-center items-center h-full w-full">
                          <img
                            src={element?.thumbnail_url}
                            className="rounded"
                            alt="thumbnail"
                          />
                          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 rounded">
                            <div className="flex justify-center items-center h-full w-full">
                              <MdEdit
                                className="size-8 text-white hover:text-darkblue cursor-pointer duration-300"
                                onClick={() => {
                                  alert("Under Development");
                                }}
                              />
                              <svg
                                onClick={() => {
                                  playVideo(element?.video_url);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-14 h-14 text-warning cursor-pointer"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                                />
                              </svg>
                              <MdDelete
                                className="size-8 text-white hover:text-red cursor-pointer duration-300"
                                onClick={() => deleteVideoHandel(element?.id)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold py-1">
                          {element?.name}
                        </h3>
                        <p className="truncate-2-lines py-2">
                          {element?.description}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}

            {/* <!-- ------------- Single YT Videos ----------- --> */}

            {isOpen && (
              <div className="fixed h-screen w-full inset-0 bg-black bg-opacity-90 backdrop-blur-2xl z-50">
                <div className="flex justify-center items-center h-full w-full">
                  {currentVideo && (
                    <div className="w-full px-3 md:px-0 md:w-2/3">
                      <div className="text-white flex justify-end mb-2 lg:mb-5 text-lg lg:text-xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-8 h-8 lg:w-10 lg:h-10 cursor-pointer"
                          onClick={() => {
                            close();
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <YoutubeVideoCard
                        videoLink={`${currentVideo}?autoplay=1`}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Videos;
