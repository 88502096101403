import React, { useEffect, useState } from "react";
import { Button, Checkbox, Chip, FormControlLabel, Stack } from "@mui/material";
import { toast } from "react-toastify";
import * as api from "../../components/utils/api/apiClient";
import Loading from "../loading/Loading";
import TagForm from "./TagForm";

function AddTagToBlog({ blogId }) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const addTag = async (tagId) => {
    setLoading(true);
    try {
      const data = {
        post_id: +blogId,
        tag_id: [tagId.id],
      };
      const res = await api.addTagsToBlog(data);
      if (res) {
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const removeTag = async (tag) => {
    setLoading(true);
    try {
      const res = await api.deleteTagByBlog(tag.id);
      if (res) {
        setRefresh(!refresh);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    const blogTags = async () => {
      setLoading(true);
      try {
        const res = await api.getAllTagsByBlog(blogId);
        console.log("getAllTagsByBlog", res.data);

        setSelectedTags(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    blogTags();
  }, [blogId, refresh]);

  useEffect(() => {
    const getTags = async () => {
      setLoading(true);
      const data = [];
      try {
        const res = await api.getAllTags();
        res.data?.forEach((element) => {
          let isIncluded = false;
          selectedTags?.forEach((ele) => {
            if (element.id === ele.tag_id) {
              isIncluded = true;
            }
          });
          if (!isIncluded) {
            data.push(element);
          }
        });
        setAllTags(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    };
    getTags();
  }, [refresh, selectedTags]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {loading && <Loading />}
      {isOpen && (
        <TagForm
          title="Create Tag"
          refreshState={() => setRefresh(!refresh)}
          isOpen={isOpen}
          handleClose={handleClose}
          whatDoYouWant="create"
        />
      )}
      <div className="flex flex-col gap-3">
        {allTags.length > 0 && (
          <div>
            <div className="flex justify-between items-center gap-5 pb-1">
              <h1 className="font-medium">All Tags</h1>
              <Button variant="text" onClick={() => setIsOpen(true)}>
                Create Tag
              </Button>
            </div>
            <div
              className={`border border-mediumgray border-opacity-50 rounded-md outline-none cursor-pointer p-1 ${
                blogId === undefined ? "" : "hover:border-black"
              }`}
            >
              {allTags.map((tag, index) => (
                <FormControlLabel
                  key={index}
                  disabled={blogId === undefined ? true : false}
                  control={
                    <Checkbox
                      checked={selectedTags.includes(tag)}
                      onChange={(e) => {
                        addTag(tag);
                        setSelectedTags([...selectedTags, tag]);
                      }}
                    />
                  }
                  label={tag.name}
                />
              ))}
            </div>
          </div>
        )}
        {selectedTags.length > 0 && (
          <div className="border border-mediumgray  border-opacity-50 rounded-md p-1">
            <h1 className="font-medium pb-1">Selected Tags</h1>
            <Stack direction="row" spacing={1} className="flex flex-wrap gap-2">
              {selectedTags.map((tag, index) => (
                <Chip
                  key={index}
                  label={
                    tag?.name ? tag?.name : "Not Sending Data From Backend"
                  }
                  onDelete={() => removeTag(tag)}
                  variant="outlined"
                />
              ))}
            </Stack>
          </div>
        )}
      </div>
    </>
  );
}

export default AddTagToBlog;
