import React from "react";

const ContactCardSkeleton = () => {
  return (
    <div className="w-full max-w-xs p-4 space-y-4 border rounded border-lightgray">
      {/* Content lines */}
      <div className="space-y-3.5">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="h-4 bg-lightgray rounded animate-pulse"
            style={{
              width: `${Math.floor(Math.random() * (100 - 70 + 1) + 70)}%`,
            }}
          />
        ))}
      </div>

      {/* Action buttons */}
      <div className="flex justify-between pt-4">
        <div className="w-20 h-8 bg-lightgray rounded animate-pulse" />
        <div className="w-20 h-8 bg-lightgray rounded animate-pulse" />
      </div>
    </div>
  );
};

export default ContactCardSkeleton;
