import React, { useState, useEffect } from "react";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { CiFilter } from "react-icons/ci";
import Loading from "../../loading/Loading";
import { deleteGalleryImage } from "../api/apiClient";

function GalleryImage({ fetchedImages, fetchImages }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPic, setCurrentPic] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      setCurrentPic((prevPic) => (prevPic + 1) % fetchedImages.length);
    } else if (event.key === "ArrowLeft") {
      setCurrentPic(
        (prevPic) => (prevPic - 1 + fetchedImages.length) % fetchedImages.length
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const delGalleryImage = async (id) => {
    setLoading(true);
    try {
      const res = await deleteGalleryImage(id);
      if (res) {
        setIsOpen(false);
        fetchImages();
        setLoading(false);
        toast.success("Image Deleted");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="w-full border border-lightgreen rounded">
        <div className="flex justify-between items-center p-3 border-b border-lightgreen">
          <h1 className="text-xl font-semibold">All Images</h1>
          <h4 className="text-lg font-semibold flex gap-1 items-center">
            <CiFilter />
            Filters
          </h4>
        </div>
        <div className="xl:columns-4 lg:columns-3 sm:columns-2 columns-auto gap-2 p-3">
          {fetchedImages?.map((item, index) => (
            <div
              key={index}
              className="mb-2"
              onClick={() => {
                setIsOpen(true);
                setCurrentPic(index);
              }}
            >
              <img
                width={900}
                height={900}
                src={item.image_url}
                className="object-cover w-full rounded"
                alt="Decoration"
              />
            </div>
          ))}
        </div>
      </div>
      {isOpen && (
        <div className="h-screen flex justify-center items-center fixed inset-0 bg-black/80">
          <div className="h-screen w-full flex justify-center items-center fixed inset-0 bg-black/50 z-20">
            <div className="flex justify-center items-center absolute top-20 right-20">
              <MdDelete
                onClick={() => delGalleryImage(fetchedImages[currentPic]?.id)}
                size={30}
                className="cursor-pointer text-white"
              />
              <IoIosClose
                size={48}
                className="cursor-pointer text-white"
                onClick={() => setIsOpen(false)}
              />
            </div>
          </div>
          <div className="w-full z-30">
            <div className="flex justify-between items-center px-24">
              <button
                className="text-white text-xl sm:text-3xl rounded-full hover:text-hoverColor"
                onClick={() => {
                  setCurrentPic(
                    (prevPic) =>
                      (prevPic - 1 + fetchedImages.length) %
                      fetchedImages.length
                  );
                }}
              >
                <BsArrowLeftCircle />
              </button>
              <div className="px-3">
                <img
                  width={500}
                  height={500}
                  src={fetchedImages[currentPic]?.image_url}
                  className="object-cover w-full text-white font-semibold text-xl rounded-sm"
                  alt="Pic not found..."
                />
              </div>
              <button
                className="text-white text-xl sm:text-3xl rounded-full hover:text-hoverColor"
                onClick={() => {
                  setCurrentPic(
                    (prevPic) => (prevPic + 1) % fetchedImages.length
                  );
                }}
              >
                <BsArrowRightCircle />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GalleryImage;
