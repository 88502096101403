import Layout from "../../components/layout/Layout";
import DashboardCard from "../../components/cards/DashboardCard";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { MdInsertComment } from "react-icons/md";
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import { RiContactsFill } from "react-icons/ri";
import ResentCard from "../../components/cards/ResentCard";
import {
  getAllUsers,
  getContacts,
  getResentComment,
} from "../../components/utils/api/apiClient";
import Loading from "../../components/loading/Loading";
import { formatDateTimeWithAMAndPM } from "../../components/utils/DateCalculate";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [comments, setComments] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    users: 0,
    comments: 0,
    blogs: 0,
    contacts: 0,
  });
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [dummyData, setDummyData] = useState({
    contacts: [
      {
        name: "John Doe",
        email: "bq4J0@example.com",
      },
      {
        name: "John Doe",
        email: "bq4J0@example.com",
      },
      {
        name: "John Doe",
        email: "bq4J0@example.com",
      },
      {
        name: "John Doe",
        email: "bq4J0@example.com",
      },
      {
        name: "John Doe",
        email: "bq4J0@example.com",
      },
    ],
  });

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const { data } = await getAllUsers();
        if (data) {
          setDashboardData({ ...dashboardData, users: data?.users?.length });
          const array = [];
          data?.users?.forEach((element) => {
            if (array.length < 7) {
              array.push(element);
            }
          });
          setUsers(array || []);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    const getContact = async () => {
      setLoading(true);
      try {
        const { data } = await getContacts();
        if (data) {
          setDashboardData({
            ...dashboardData,
            contacts: data?.contact_us_forms?.length,
          });
          const array = [];
          data?.contact_us_forms?.forEach((element) => {
            if (array.length < 7) {
              array.push(element);
            }
          });
          setContacts(array || []);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    const getResentComments = async () => {
      setLoading(true);
      try {
        const { data } = await getResentComment();
        if (data) {
          setDashboardData({ ...dashboardData, comments: data?.length });
          setComments(data || []);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    // Simulating data fetch
    setChartData([
      { name: "Jan", users: 4 },
      { name: "Feb", users: 3 },
      { name: "Mar", users: 2 },
      { name: "Apr", users: 2 },
      { name: "May", users: 1 },
      { name: "Jun", users: 2 },
      { name: "Jul", users: 3 },
      { name: "Aug", users: 4 },
      { name: "Sep", users: 3 },
      { name: "Oct", users: 2 },
      { name: "Nov", users: 3 },
      { name: "Dec", users: 4 },
    ]);
    getUsers();
    getResentComments();
    getContact();
  }, []);

  return (
    <Layout>
      <div>
        {loading && <Loading />}
        <div className="w-full flex flex-col md:flex-row gap-3">
          <div className="w-full md:w-[65%] flex flex-col gap-3">
            <div className="w-full flex flex-col md:flex-row gap-3">
              <DashboardCard
                data={{
                  title: "Total Users",
                  count: dashboardData?.users || 0,
                  icon: <FaUsers size={24} />,
                  color: "from-darkblue to-blue",
                  accentColor: "bg-darkblue",
                  average: 5,
                }}
              />
              <DashboardCard
                data={{
                  title: "Total Comments",
                  count: dashboardData?.comments || 0,
                  icon: <MdInsertComment size={24} />,
                  color: "from-olivegreen to-lightgreen",
                  accentColor: "bg-darkblue",
                  average: 5,
                }}
              />
            </div>
            {/* User Growth */}
            <div className="border border-lightgreen w-full rounded">
              <div>
                <h1 className="text-lg font-semibold p-3 border-b border-lightgreen">
                  User Growth
                </h1>
              </div>
              <div className="w-full">
                <ResponsiveContainer height={300} className={"-ml-5 my-3"}>
                  <LineChart data={chartData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Line type="natural" dataKey="users" stroke="#8EA250" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <ResentCard title={"Resent Users"} link="/users">
                  <div>
                    {users.length > 0 ? (
                      <>
                        {users?.map((user, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-4 p-2.5 bg-white"
                          >
                            {/* <div className="w-12 h-12 bg-lightgray rounded-full"></div> */}
                            <Avatar src={user?.profile_photo_url} alt="logo" />
                            <div className="flex-1">
                              <div className="rounded capitalize font-semibold">
                                {user?.username}
                              </div>
                              <div className="text-xs text-gray rounded">
                                {formatDateTimeWithAMAndPM(user?.created_at)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      dummyData?.contacts?.map((_, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-4 p-2.5 bg-white animate-pulse"
                        >
                          <div className="w-12 h-12 bg-lightgray rounded-full"></div>
                          <div className="flex-1">
                            <div className="h-4 bg-lightgray rounded w-3/4 mb-2"></div>
                            <div className="h-3 bg-lightgray rounded w-1/2"></div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </ResentCard>
              </div>
              <div>
                <ResentCard title={"Resent Comments"} link="#">
                  <div>
                    {comments.length > 0 ? (
                      <>
                        {comments?.map((comment, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-4 p-2.5 bg-white cursor-pointer"
                            onClick={() => {
                              navigate(`/blogs/${comment?.post_id}`);
                            }}
                          >
                            <div
                              className={`p-3 rounded-full bg-darkblue bg-opacity-50 text-white`}
                            >
                              <MdInsertComment size={24} />
                            </div>
                            <div className="flex-1">
                              <div className="rounded capitalize font-semibold">
                                {comment?.content}
                              </div>
                              <div className="text-xs text-gray rounded">
                                {formatDateTimeWithAMAndPM(comment?.created_at)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      dummyData?.contacts?.map((_, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-4 p-2.5 bg-white animate-pulse"
                        >
                          <div className="w-12 h-12 bg-lightgray rounded-full"></div>
                          <div className="flex-1">
                            <div className="h-4 bg-lightgray rounded w-3/4 mb-2"></div>
                            <div className="h-3 bg-lightgray rounded w-1/2"></div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </ResentCard>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-3 md:w-[35%]">
            <DashboardCard
              data={{
                title: "Total Blogs",
                count: 5,
                icon: <BsFileEarmarkRichtextFill size={24} />,
                color: "from-purple to-blue",
                accentColor: "bg-darkblue",
                average: -5,
              }}
            />
            <DashboardCard
              data={{
                title: "Total Contacts",
                count: dashboardData?.contacts || 0,
                icon: <RiContactsFill size={24} />,
                color: "from-gray to-blue",
                accentColor: "bg-darkblue",
                average: -2,
              }}
            />
            {/* Resent Contacts */}
            <div>
              <ResentCard title={"Resent Contacts"} link="/contacts">
                <div>
                  {contacts.length > 0 ? (
                    <>
                      {contacts?.map((contact, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-4 p-2.5 bg-white cursor-pointer"
                          onClick={() => {
                            navigate(`/contacts`);
                          }}
                        >
                          <Avatar src={contact?.profile_photo_url} alt="logo" />
                          <div className="flex-1">
                            <div className="rounded capitalize font-semibold">
                              {contact?.name}
                            </div>
                            <div className="text-xs text-gray rounded">
                              {contact?.email}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    dummyData?.contacts?.map((_, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-4 p-2.5 bg-white animate-pulse"
                      >
                        <div className="w-12 h-12 bg-lightgray rounded-full"></div>
                        <div className="flex-1">
                          <div className="h-4 bg-lightgray rounded w-3/4 mb-2"></div>
                          <div className="h-3 bg-lightgray rounded w-1/2"></div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </ResentCard>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
