import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { instance } from "../../../app/AxiosConfig";

const GalleryModal = ({ onClose, selectedImage }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await instance.get("/gallery/list");
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className=" relative w-[70%] h-[90%] bg-white text-center rounded-md px-4 overflow-y-scroll">
        <div className="flex justify-between  items-center sticky top-0 z-10 bg-white">
          <IoIosClose
            size={32}
            className="absolute top-0 right-2 cursor-pointer"
            onClick={onClose}
          />
          <h2 className="text-lg font-semibold">Select an Image</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.image_url}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                selectedImage(`<img src=${image.image_url} alt='thumbnail' />`)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
