import React from "react";

function SubmitButton({ functions, disabled, name = "Submit" }) {
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => {
          if (functions) {
            functions();
          }
        }}
        className={`w-full md:w-auto bg-darkblue text-white rounded-md px-6 py-2 ${
          disabled && "bg-gray"
        }`}
      >
        {name}
      </button>
    </>
  );
}

export default SubmitButton;
