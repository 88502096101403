import React from 'react';

const DeletePopup = ({ Title, Delete, Cancel }) => {
  return (
    <>
      <div className=" fixed inset-0 bg-black/70 z-50 flex justify-center items-center">
        <div className=" relative flex justify-center items-center w-[300px] md:w-[450px] h-52 bg-white text-center rounded-md px-8 py-4">
          <div className="flex flex-col gap-5">
            <h2 className="text-md md:text-lg font-bold">{Title}</h2>
            <div className="flex justify-center gap-4">
              <button
                onClick={Cancel}
                className="border border-black rounded-md px-6 sm:px-12 py-2"
              >
                Cancel
              </button>
              <button
                onClick={Delete}
                className="bg-red text-white rounded-md px-6 sm:px-12 py-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletePopup;
