import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { IoIosClose } from "react-icons/io";
import { FaImage } from "react-icons/fa6";
import ImageUpload from "../utils/image/ImageUpload";
import GalleryModal from "../utils/gallery/GalleryModel";
import { getAllCategories } from "../utils/api/apiClient";
import CategoryForm from "./CategoryForm";
import Loading from "../loading/Loading";
import SubmitButton from "../buttons/SubmitButton";
import * as api from "../utils/api/apiClient";
import { useNavigate } from "react-router-dom";

function BlogForm({ blogData }) {
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "video"],
      ],
    },
  };
  const navigate = useNavigate();
  const [avatarURL, setAvatarURL] = useState("");
  const [showGallery, setShowGallery] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isCategoryCreate, setIsCategoryCreate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    meta_description: "",
    meta_keywords: "",
    post_thumbnail: {},
    category_id: "",
    tags: [],
    content: "",
  });

  // Get All Categories
  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const res = await getAllCategories();
        if (res) {
          setIsLoading(false);
          setCategories(res?.data || []);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    fetchCategories();
  }, [refresh]);

  const handleImageUpload = (croppedImage, imageUrl) => {
    setAvatarURL(imageUrl);
    setFormData({ ...formData, post_thumbnail: croppedImage });
  };

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectedImage = (url) => {
    setFormData({ ...formData, content: formData.content + url });
    setShowGallery(!showGallery);
  };

  // Add Post Function
  const CreateBlog = async () => {
    setIsLoading(true);
    const appendFormData = new FormData();
    appendFormData.append("category_id", formData.category_id * 1);
    appendFormData.append("title", formData.title);
    appendFormData.append("content", formData.content);
    appendFormData.append("meta_description", formData.meta_description);
    appendFormData.append("post_thumbnail", formData.post_thumbnail);
    appendFormData.append("meta_keywords", formData.meta_keywords);
    try {
      const { data } = await api.createBlog(appendFormData);
      if (data) {
        setFormData({
          category_id: "",
          title: "",
          meta_description: "",
          meta_keywords: "",
          content: "",
          post_thumbnail: "",
        });
        setAvatarURL("");
        navigate(`/blog_add_tags/${data.id}`);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // Update Blog Function
  const updateBlog = async () => {
    setIsLoading(true);
    try {
      const data = new FormData();
      data.append("category_id", formData.category_id);
      data.append("title", formData.title);
      data.append("content", formData.content);
      data.append("meta_description", formData.meta_description);
      data.append("post_thumbnail", formData.post_thumbnail);
      data.append("meta_keywords", formData.meta_keywords);

      const res = await api.updateBlog(`/posts/update/${blogData?.id}`, data);

      if (res) {
        setFormData({
          category_id: "",
          title: "",
          meta_description: "",
          meta_keywords: "",
          content: "",
          post_thumbnail: "",
        });
        setAvatarURL("");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {/* Category Create Modal */}
      {isCategoryCreate && (
        <CategoryForm
          title={"Create Category"}
          pageRefresh={() => {
            setRefresh(!refresh);
          }}
          closePopup={() => setIsCategoryCreate(!isCategoryCreate)}
        />
      )}
      {/* Gallery Modal For Blog Content */}
      {showGallery && (
        <GalleryModal
          onClose={() => setShowGallery(!showGallery)}
          selectedImage={selectedImage}
        />
      )}
      <div className="h-auto bg-lightgray">
        <div className="block md:flex gap-4">
          <div className="w-full md:w-[70%] gap-2">
            <div className="w-full rounded-lg shadow p-6">
              <div className="mb-4">
                <label className="block mb-2">Blog Title</label>
                <input
                  type="text"
                  value={formData?.title}
                  className="w-full p-2 border border-border rounded-lg"
                  name="title"
                  onChange={handelChange}
                />
              </div>
              <div className="mb-4">
                <div className="flex items-center justify-between mb-3">
                  <h3 className="text-lg font-semibold">Category</h3>
                  <button
                    onClick={() => setIsCategoryCreate(true)}
                    className="text-sm text-darkblue"
                  >
                    Create Category
                  </button>
                </div>
                <div className="mb-4">
                  <select
                    className="w-full h-8"
                    value={formData?.category_id}
                    onChange={(e) =>
                      setFormData({ ...formData, category_id: e.target.value })
                    }
                  >
                    <option value="">Choose The Category</option>
                    {categories?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Blog Keyword</label>
                <input
                  type="text"
                  value={formData?.meta_keywords}
                  name="meta_keywords"
                  className="w-full p-2 border border-border rounded-lg"
                  onChange={handelChange}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Blog Description</label>
                <input
                  type="text"
                  name="meta_description"
                  value={formData?.meta_description}
                  className="w-full p-2 border border-border rounded-lg"
                  onChange={handelChange}
                />
              </div>
              <div className=" relative mb-4">
                <label className="block mb-2">Blog Content</label>
                <button
                  onClick={() => setShowGallery(!showGallery)}
                  className="absolute top-[46px] right-[174px]"
                >
                  <FaImage />
                </button>
                <div className="quill-editor">
                  <ReactQuill
                    theme="snow"
                    value={formData?.content}
                    onChange={(e) => setFormData({ ...formData, content: e })}
                    modules={modules}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-[30%] space-y-8">
            <div className="rounded-lg shadow w-full p-6">
              <h3 className="text-lg font-semibold mb-4">
                Upload <span className="text-olivegreen">Thumbnail</span>
              </h3>
              <div className="relative aspect-w-16 aspect-h-9 border border-black">
                {avatarURL ? (
                  <>
                    <img
                      src={avatarURL}
                      alt="Preview"
                      className="object-cover rounded"
                    />
                    <div className="absolute top-2 right-2">
                      <IoIosClose
                        size={32}
                        className="text-white text-xl rounded-full cursor-pointer"
                        onClick={() => setAvatarURL("")}
                      />
                    </div>
                  </>
                ) : (
                  <ImageUpload
                    onImageUpload={handleImageUpload}
                    fixedAspectRatio={true}
                  />
                )}
              </div>
            </div>
            {/* <div className="p-6 rounded-lg shadow">
              <div className="flex items-center justify-between mb-3">
                <h3 className="text-lg font-semibold">Tags</h3>
                <button onClick={MyModalTag} className="text-sm text-darkblue">
                  Create Tag
                </button>
              </div>
              <div className="flex items-center">
                <ComboBox
                  selectedTagId={(data) => {
                    selectedTag(data);
                  }}
                  editSelectedTag={editTag}
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-auto py-4 text-center">
          {blogData?.id ? (
            <SubmitButton
              name="Update Changes"
              functions={() => updateBlog()}
            />
          ) : (
            <>
              <SubmitButton name="Save" functions={() => CreateBlog()} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default BlogForm;
