import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import * as api from "../../components/utils/api/apiClient";
import Loading from "../loading/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BlogPage = ({ data, pageRefresh }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const deleteABlog = async (id) => {
    setLoading(true);
    try {
      const res = await api.deleteBlog(id);
      pageRefresh();
      if (res) {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const togglePublishedStatus = async (blogId, isPublished) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("is_published", isPublished);

    try {
      const res = await api.PublishBlog(blogId, formData);
      if (res.data.is_published === true) {
        toast.success("Blog Published successfully");
      } else {
        toast.success("Blog Unpublished successfully");
      }

      setLoading(false);
      pageRefresh();
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full shadow-md h-auto md:h-[350px]">
      {loading && <Loading />}
      <div className="w-full md:w-[40%] overflow-hidden rounded-r-3xl">
        <img
          src={data?.thumbnail}
          alt="BlogImage"
          className="w-full h-full object-cover hover:scale-105 duration-300"
        />
      </div>
      <div className="w-full md:w-[60%] p-4 space-y-5">
        <div className="flex justify-between items-start">
          <button
            className="bg-blue px-4 py-1 text-sm text-nowrap rounded-sm"
            aria-label="Blog Category"
          >
            {data?.category_name}
          </button>
          <div className="flex items-center gap-2">
            <MdDelete
              onClick={() => deleteABlog(data?.id)}
              size={28}
              className="bg-black p-1 text-white rounded-full cursor-pointer"
              aria-label="Delete Blog"
            />
            <MdEdit
              // onClick={editbuttonClick} TODO: onClick open popup for edit
              size={28}
              className="bg-black p-1 text-white rounded-full cursor-pointer"
              aria-label="Edit Blog"
            />
            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
              <input
                type="checkbox"
                name="autoSaver"
                className="sr-only"
                checked={data?.published}
                onChange={() => {
                  togglePublishedStatus(data.id, !data?.published);
                }}
                aria-label="Auto Save"
              />
              <span
                className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
                  data?.published ? "bg-blue" : "bg-[#CCCCCE]"
                }`}
              >
                <span
                  className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
                    data?.published ? "translate-x-6" : ""
                  }`}
                ></span>
              </span>
            </label>
          </div>
        </div>

        <h1 className="text-xl font-semibold">{data?.title}</h1>
        <h2 className="line-clamp-2">{data?.meta_description}</h2>
        <div className="line-clamp-3">{data?.keywords}</div>
        <button
          onClick={() => navigate(`/blogs/${data?.id}`)}
          className="flex items-center hover:bg-lightgray border border-gray px-4 py-2 gap-2"
          aria-label="Read More"
        >
          Read More
          <span>
            <FaArrowRight />
          </span>
        </button>
      </div>
    </div>
  );
};

export default BlogPage;
