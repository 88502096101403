// import HoverButton from '@/components/Admincomponents/HoverButton';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { instance } from "../../app/AxiosConfig";
import HoverButton from "../../components/hoverbutton/HoverButton";
import DeletePopup from "../../components/deletepop/DeletePopup";
import MyModal from "../../components/mymodel/MyModel";
import Layout from "../../components/layout/Layout";
import * as api from "../../components/utils/api/apiClient";
const Tags = () => {
  const [tag, setTag] = useState("");
  const [show, setShow] = useState(false);
  const [allTag, setAllTag] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPopupValue, setEditPopupValue] = useState("");
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addTag = async () => {
    setIsLoading(true);
    try {
      const res = await instance.post("/tags/create", { name: tag });
      if (res) {
        setTag("");
        setIsLoading(false);
        toast.success("Tag Created");
        fetchTags();
      }
    } catch (err) {
      console.log(err);
      toast.info(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const res = await api.getAllTags();
      setAllTag(res?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const deleteTag = async () => {
    try {
      const res = await instance.delete(`/tags/delete/${selectedTagId}`);
      if (res) {
        toast.success("Tag Deleted Successfully");
        setShowDeletePopup(false);
        fetchTags(); // Refresh the tags list
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const updateTag = async () => {
    try {
      const res = await instance.patch(`/tags/update/${selectedTagId}`, {
        name: editPopupValue,
      });

      if (res) {
        setShowEditPopup(false);
        fetchTags();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <div>
        {showDeletePopup && (
          <DeletePopup
            Title="Are you sure you want to delete?"
            Cancel={() => setShowDeletePopup(false)}
            Delete={deleteTag}
          />
        )}
        {showEditPopup && (
          <MyModal
            setInputvalue={(val) => setEditPopupValue(val)}
            Inputvalue={editPopupValue}
            Title="Update the Category Name"
            Cancel={() => setShowEditPopup(false)}
            click={updateTag}
            buttonName="Update"
          />
        )}

        {/********* Add tags **********/}
        <div className="inline md:flex justify-between items-center">
          <div className="text-3xl font-extrabold tracking-widest pb-3 md:pb-0">
            ALL&nbsp;<span className="text-olivegreen">TAGS</span>
          </div>
          <button
            type="button"
            onClick={() => setShow(true)}
            className="bg-darkblue hover:bg-white hover:text-blue border-2 border-blue px-12 py-2 rounded-md cursor-pointer"
          >
            ADD TAGS
          </button>
        </div>
        {show && (
          <MyModal
            setInputvalue={(val) => setTag(val)}
            Inputvalue={tag}
            Title="Add The Tag Name"
            Cancel={() => setShow(!show)}
            buttonName="Add"
            click={() => {
              addTag();
              setShow(!show);
            }}
          />
        )}

        {/********** All Tags  *******/}
        <div className="py-6">
          {allTag.map((tag) => (
            <div key={tag.id}>
              <HoverButton
                Name={tag.name}
                openDeletePopup={() => {
                  setShowDeletePopup(true);
                  setSelectedTagId(tag.id);
                }}
                openEditPopup={() => {
                  setShowEditPopup(true);
                  setEditPopupValue(tag.name);
                  setSelectedTagId(tag.id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Tags;
