import React, { useEffect, useMemo, useState } from "react";
import { formatDateTimeWithAMAndPM } from "../../components/utils/DateCalculate";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Avatar } from "@mui/material";
import Layout from "../../components/layout/Layout";
import { getAllUsers } from "../../components/utils/api/apiClient";
import Loading from "../../components/loading/Loading";
import { FaUserCheck, FaUserMinus } from "react-icons/fa";

function Users() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const { data } = await getAllUsers();
        if (data) {
          const array = [];
          data?.users.forEach((element) => {
            const obj = { ...element };
            obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
            obj.is_active = element.is_active ? (
              <FaUserCheck size={25} className="text-green" />
            ) : (
              <FaUserMinus size={25} className="text-red" />
            );
            obj.profile_photo_url = (
              <Avatar src={element?.profile_photo_url} alt="logo" />
            );
            array.push(obj);
          });
          setData(array);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getUsers();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "profile_photo_url",
        header: "Profile",
        size: 100,
        filter: false,
      },
      {
        accessorKey: "username",
        header: "Full Name",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 100,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 50,
      },
      {
        accessorKey: "is_active",
        header: "Active",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <Layout>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <MaterialReactTable table={table} />
      </div>
    </Layout>
  );
}

export default Users;
