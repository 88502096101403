import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PreviewCard from "../../../../components/preview/PreviewCard";
import { instance } from "../../../../app/AxiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../components/layout/Layout";
import StepsCounter from "../../../../components/ui/StepsCounter";
import AddTagToBlog from "../../../../components/forms/AddTagToBlog";

const AddTagsToBlog = () => {
  // const navigate = useNavigate();
  const { id } = useParams();
  // const [blogData, setBlogData] = useState();

  // console.log(blogData);

  // const fetchBlog = async () => {
  //   try {
  //     const res = await instance.get(`/posts/post/${id}`);
  //     setBlogData(res?.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const blogPublished = async (blogId) => {
  //   const data = new FormData();
  //   data.append("is_published", true);
  //   try {
  //     const res = await instance.patch(`/posts/update/${blogId}`, data);
  //     if (res) {
  //       toast.success("Blog Published Successfully");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     if (err.response.data.message) {
  //       toast.error(err.response.data.message);
  //     } else {
  //       toast.error(err.message);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchBlog();
  // }, []);

  return (
    <Layout>
      <div>
        <StepsCounter step={1} />
        {/* <div className="w-full flex justify-center items-center">
          <div>
            <h3>Add Tags To Blog</h3>
          </div>
          <div>Create new Tag</div>
        </div> */}
        <AddTagToBlog blogId={id} />
      </div>
    </Layout>
  );
};

export default AddTagsToBlog;
