import React from "react";

const ContactCard = ({ data }) => {
  return (
    <div className="w-full max-w-xs p-4 space-y-4 border rounded border-lightgray">
      {/* Content lines */}
      <div className="space-y-2">
        <ul className="flex flex-col gap-2">
          <li className="font-semibold">
            Name: <span className="font-normal">{data?.name}</span>
          </li>
          <li className="font-semibold">
            Subject: <span className="font-normal">{data?.subject}</span>
          </li>
          <li className="font-semibold">
            Message: <span className="font-normal">{data?.message}</span>
          </li>
        </ul>
      </div>

      {/* Action buttons */}
      <div className="flex justify-between items-center pt-4">
        <a
          href={`tel:+${data?.phone}`}
          className="w-full md:w-auto bg-darkblue text-white rounded-md px-4 py-1"
        >
          Call Us
        </a>
        <a
          href={`mailto:${data?.email}`}
          className="w-full md:w-auto border border-darkblue text-darkblue rounded-md px-4 py-1"
        >
          Mail Us
        </a>
      </div>
    </div>
  );
};

export default ContactCard;
