import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { instance } from "../../app/AxiosConfig";
import BlogPage from "../../components/blogpage/BlogPage";
import Loading from "../../components/loading/Loading";
import DeletePopup from "../../components/deletepop/DeletePopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as api from "../../components/utils/api/apiClient";
import Layout from "../../components/layout/Layout";

const Blogs = () => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categories") || "";
  const [allBlogs, setAllBlogs] = useState([]);
  const [allcategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBlogId, setDeleteBlogId] = useState("");

  const getCategory = async () => {
    try {
      const res = await api.getAllCategories();
      setAllCategories(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const searchBlogByCategory = async () => {
      if (categoryId === "") {
        getAllBlogs();
        return;
      }
      setLoading(true);
      try {
        const res = await api.getAllBlogsByCategoryId(categoryId);
        setAllBlogs(res?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    searchBlogByCategory();
  }, [categoryId, refresh]);

  const getAllBlogs = async () => {
    setLoading(true);
    try {
      const res = await instance.get(
        `/posts/list?limit=1000&page=${searchParams.get("page") || "1"}`
      );
      setAllBlogs(res?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBlogs();
  }, [searchParams.get("page"), refresh]);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Layout>
      <div className="py-4">
        <select
          value={categoryId}
          onChange={(e) => {
            const selectedCategory = e.target.value || "";
            const path = selectedCategory
              ? `/blogs?categories=${selectedCategory}`
              : "/blogs";
            navigate(path);
          }}
          className="w-full m-auto px-2 py-1 focus:outline-none border-2 border-black"
          id="options"
        >
          <option value="">ALL BLOGS OF ALL CATEGORIES</option>
          {allcategories &&
            allcategories?.map((e, i) => (
              <option key={i} value={e.id}>
                {e.name}
              </option>
            ))}
        </select>
      </div>
      <div className="space-y-5">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loading />
          </div>
        ) : allBlogs.length > 0 ? (
          allBlogs.map((blog, i) => (
            <div key={i}>
              <BlogPage
                data={blog}
                pageRefresh={() => {
                  setRefresh(!refresh);
                }}
              />
            </div>
          ))
        ) : (
          <div>NO BLOG FOUND</div>
        )}
      </div>
      {!loading && allBlogs.length > 0 && (
        <div className="flex justify-center py-4">
          <Stack spacing={5}>
            <Pagination
              count={allBlogs[0].total_pages}
              page={searchParams.get("page")}
              onChange={(event, page) => {
                navigate(`?page=${page}`);
              }}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </div>
      )}
    </Layout>
  );
};

export default Blogs;
