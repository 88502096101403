import { MdDelete } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';

const HoverButton = ({ Name, openDeletePopup, openEditPopup }) => {
  return (
    <div>
      <div className="flex justify-between w-full bg-lightgray py-2 px-4 rounded">
        <div className="font-semibold capitalize">{Name}</div>
        <div className="flex gap-4">
          <FaEdit
            size={22}
            className="cursor-pointer"
            onClick={openEditPopup}
          />
          <MdDelete
            size={24}
            className="hover:text-red cursor-pointer"
            onClick={openDeletePopup}
          />
        </div>
      </div>
      <hr className="bg-black  w-full" />
    </div>
  );
};

export default HoverButton;
