import React from "react";
import { IoIosTrendingUp } from "react-icons/io";
import { IoIosTrendingDown } from "react-icons/io";

function DashboardCard({ data }) {
  const { title, icon, color, accentColor, average, count } = data;
  const isPositiveTrend = average >= 0;

  return (
    <>
      <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-120 w-full">
        <div className={`bg-gradient-to-br ${color} p-6 text-white`}>
          <div className="flex justify-between items-start mb-4">
            <div>
              <h2 className="text-lg font-semibold mb-1">{title}</h2>
              <p className="text-4xl font-bold">
                {count?.toLocaleString() || 0}
              </p>
            </div>
            <div className={`p-3 rounded-full ${accentColor} bg-opacity-30`}>
              {icon}
            </div>
          </div>
          <div className="flex items-center mt-2">
            {isPositiveTrend ? (
              <IoIosTrendingUp size={20} className="mr-2" />
            ) : (
              <IoIosTrendingDown size={20} className="mr-2" />
            )}
            <span
              className={`text-sm font-medium ${
                isPositiveTrend ? "text-green" : "text-red"
              }`}
            >
              {isPositiveTrend ? "+" : ""}
              {`${average}%`}
            </span>
            <span className="text-sm ml-2 text-white/80">
              {"vs last month"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardCard;
