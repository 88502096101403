import "react-quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import MyModal from "../../../components/mymodel/MyModel";
import { toast } from "react-toastify";
import GalleryModal from "../../../components/utils/gallery/GalleryModel";
import { formInstance, instance } from "../../../app/AxiosConfig";
import AddBlog from "../../../components/input/AddBlog";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import StepsCounter from "../../../components/ui/StepsCounter";
import BlogForm from "../../../components/forms/BlogForm";

const CreateBlog = () => {
  const [searchParams] = useSearchParams();
  const editblogId = searchParams.get("editblog");
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [avatarURL, setAvatarURL] = useState("");
  const [editTag, setEditTag] = useState([]);
  const [postData, setPostData] = useState({
    category_id: 0,
    title: "",
    meta_description: "",
    meta_keywords: "",
    content: "",
    post_thumbnail: "",
  });
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showgalleryModal, setShowGalleryModal] = useState(false);
  const [showModalTag, setShowModalTag] = useState(false);
  const [tag, setTag] = useState("");
  const [selectedTagId, setSelectedTagId] = useState([]);
  const [blogId, setBlogId] = useState(searchParams.get("editblog"));

  const fetchCategories = async () => {
    try {
      const res = await instance.get("/categories/list");
      setCategories(res?.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [isLoading]);

  useEffect(() => {
    if (editblogId) {
      const fetchBlog = async () => {
        try {
          const res = await instance.get(`/posts/post/${editblogId}`);
          if (res.data) {
            setPostData({
              ...postData,
              content: res.data.content,
              category_id: res.data.category_id,
              title: res.data.title,
              meta_description: res.data.meta_description,
              meta_keywords: res.data.meta_keywords,
              post_thumbnail: res.data.thumbnail,
            });
            setEditTag(res.data.tag_name);

            setAvatarURL(res.data.thumbnail);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchBlog();
    }
  }, [editblogId]);

  const addPost = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("category_id", postData.category_id * 1);
    data.append("title", postData.title);
    data.append("content", postData.content);
    data.append("meta_description", postData.meta_description);
    data.append("post_thumbnail", postData.post_thumbnail);
    data.append("meta_keywords", postData.meta_keywords);

    try {
      const res = await formInstance.post("/posts/create", data);
      if (res?.data?.id) {
        setBlogId(res?.data?.id * 1);
      }
      setPostData({
        category_id: 0,
        title: "",
        meta_description: "",
        meta_keywords: "",
        content: "",
        post_thumbnail: "",
      });
      setAvatarURL("");
      navigate(`/post/${res?.data?.id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const updateBlog = async () => {
    try {
      const data = new FormData();
      data.append("category_id", postData.category_id);
      data.append("title", postData.title);
      data.append("content", postData.content);
      data.append("meta_description", postData.meta_description);
      data.append("post_thumbnail", postData.post_thumbnail);
      data.append("meta_keywords", postData.meta_keywords);
      const res = await formInstance.patch(`/posts/update/${editblogId}`, data);
      // console.log('updateblog', res?.data);
      if (res?.data?.id) {
        postTag(res?.data?.id * 1);
      }
      navigate(`/post/${res?.data?.id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const addcategory = async () => {
    setIsLoading(true);
    try {
      const res = await instance.post("/categories/create", { name: category });
      if (res) {
        setCategory("");
        setIsLoading(false);
        toast.success("Category Created");
        fetchCategories();
      }
    } catch (err) {
      console.log(err);
      console.log(err);
      toast.info(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const selectedImage = (url) => {
    setPostData({ ...postData, content: postData.content + url });
    setShowGalleryModal(!showgalleryModal);
  };

  const addTag = async () => {
    setIsLoading(true);
    try {
      const res = await instance.post("/tags/create", { name: tag });
      if (res) {
        setTag("");
        setIsLoading(false);
        toast.success("Tag Created");
      }
    } catch (err) {
      console.log(err);
      toast.info(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const postTag = async (selected_id) => {
    try {
      const res = await instance.post("/post_tags/create", {
        post_id: blogId * 1,
        tag_id: selected_id,
      });
      if (res) {
        setSelectedTagId([]);
        setShowModalTag(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <StepsCounter step={0} />
      <BlogForm />
      {/* <div className="shadow-lg p-3 bg-lightgray rounded-md">
        <div className="block md:flex justify-between items-center w-full">
          {editblogId ? (
            <div className="text-3xl font-extrabold tracking-widest">
              UPDATE&nbsp;<span className="text-olivegreen">BLOG</span>
            </div>
          ) : (
            <div className="text-3xl font-extrabold tracking-widest">
              ADD&nbsp;<span className="text-olivegreen">BLOG</span>
            </div>
          )}
          <div className="w-auto py-4">
            {editblogId ? (
              <button
                onClick={updateBlog}
                className="w-full bg-darkblue text-white rounded-md px-8 py-3"
              >
                UPDATE POST
              </button>
            ) : (
              <>
                <div className="flex flex-col md:flex-row gap-4 h-auto w-auto ">
                  <button
                    onClick={addPost}
                    className="w-full md:w-auto bg-darkblue text-white rounded-md px-8 py-3"
                  >
                    SAVE POST
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="">
          <AddBlog
            editTag={editTag}
            postData={postData}
            setPostData={setPostData}
            avatarURL={avatarURL}
            setAvatarURL={setAvatarURL}
            categories={categories}
            ShowMyModal={() => setShow(true)}
            MyModalTag={() => setShowModalTag(true)}
            customImageHandler={() => setShowGalleryModal(!showgalleryModal)}
            selectedTag={(data) => {
              postTag(data);
            }}
          />
          {show && (
            <MyModal
              setInputvalue={(val) => setCategory(val)}
              Inputvalue={category}
              Title="Add The Category Name"
              Cancel={() => setShow(!show)}
              buttonName="Add"
              click={() => {
                addcategory();
                setShow(!show);
              }}
            />
          )}
          <div>
            {showModalTag && (
              <MyModal
                setInputvalue={(val) => setTag(val)}
                Inputvalue={tag}
                Title="Add The Tag Name"
                Cancel={() => setShowModalTag(!showModalTag)}
                buttonName="Add"
                click={() => {
                  addTag();
                  setShowModalTag(!showModalTag);
                }}
              />
            )}
          </div>
        </div>
        {showgalleryModal && (
          <GalleryModal
            onClose={() => setShowGalleryModal(!setShowGalleryModal)}
            selectedImage={selectedImage}
          />
        )}
      </div> */}
    </Layout>
  );
};

export default CreateBlog;
