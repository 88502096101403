import React, { useState } from "react";
import Toast from "../toast/Toast";
import Sidebar from "../app/Sidebar";

export default function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex">
      <div className="z-20">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>
      <div className="w-full">
        <Toast />
        {/* children  */}
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
}
