import React, { useContext } from "react";
import userPic from "../../assets/profile.png";
import {
  FaHashtag,
  FaUsers,
  FaCommentAlt,
  FaBlogger,
  FaQuestion,
  FaVideo,
} from "react-icons/fa";
import {
  MdCategory,
  MdContacts,
  MdDashboard,
  MdOutlinePostAdd,
} from "react-icons/md";
import { deleteCookie } from "../utils/api/cookies";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";
import { IoIosImages } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Sidebar({ isOpen, toggleSidebar, children, isCss = false }) {
  const { isLogin, profileDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogOut = () => {
    deleteCookie("access_token");
    deleteCookie("refresh_token");
    toast.success("Logout Successfully");
    navigate("/login");
  };

  const TabLink = [
    { name: "Dashboard", link: "/", icon: <MdDashboard size={24} /> },
    {
      name: "Add Category",
      link: "/add_category",
      icon: <MdCategory size={24} />,
    },
    {
      name: "Add Posts",
      link: "/blog_create",
      icon: <MdOutlinePostAdd size={24} />,
    },
    {
      name: "Add Tags",
      link: "/tags",
      icon: <FaHashtag size={24} />,
    },
    {
      name: "All Blogs",
      link: "/blogs",
      icon: <FaBlogger size={24} />,
    },
    { name: "Videos", link: "/videos", icon: <FaVideo size={24} /> },
    { name: "Users", link: "/users", icon: <FaUsers size={24} /> },
    { name: "Contacts", link: "/contacts", icon: <MdContacts size={24} /> },
    // {
    //   name: "Comments",
    //   link: "/comments",
    //   icon: <FaCommentAlt size={24} />,
    // },
    {
      name: "Gallery",
      link: "/gallery",
      icon: <IoIosImages size={24} />,
    },
    { name: "FAQ", link: "/faq", icon: <FaQuestion size={24} /> },
    { name: "Logout", icon: <CiLogout size={24} /> },
  ];

  return (
    <>
      {children}
      <div
        className={`w-full h-full flex flex-col gap-2 bg-red ${
          isCss && "z-50 w-52"
        } -translate-y-1`}
      >
        <div
          className={`fixed inset-y-0 left-0 transform transition-transform duration-300 sm:relative sm:translate-x-0 sm:flex  ${
            isOpen ? "translate-x-full" : "-translate-x-full"
          } `}
        >
          <div
            className={`bg-dark-purple h-screen p-5 w-60 duration-300 relative`}
          >
            <div className="flex gap-2 items-center text-white text-opacity-70">
              <img src={userPic} alt="profile" className="w-10" />
              <div>
                <p className="text-xs">Hello,</p>
                <h4 className="font-medium">
                  {isLogin && profileDetails?.full_name
                    ? profileDetails?.full_name
                    : "Guest"}
                </h4>
              </div>
            </div>
            <ul className="pt-2">
              {TabLink.map((tab, index) => (
                <Link
                  onClick={() => {
                    if (tab.name === "Logout") {
                      handleLogOut();
                    }
                  }}
                  to={tab.link}
                  key={index}
                >
                  <li
                    className={`text-gray text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                      pathname === tab.link && "bg-light-white"
                    }`}
                  >
                    <span className="text-2xl block float-left">
                      {tab.icon}
                    </span>
                    <span
                      className={`text-base font-medium flex-1 duration-200`}
                    >
                      {tab.name}
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
