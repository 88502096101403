import React, { useEffect, useState } from "react";
import SubmitButton from "../buttons/SubmitButton";
import Loading from "../loading/Loading";
import { toast } from "react-toastify";
import * as api from "../../components/utils/api/apiClient";

function TagForm({
  title,
  refreshState,
  data,
  whatDoYouWant = "create",
  handleClose,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [allTag, setAllTag] = useState([]);

  useEffect(() => {
    // TODO: if update category than use data to set formData and update function call
  }, []);

  const CreateTag = async () => {
    setIsLoading(true);
    try {
      const res = await api.createTags(formData);
      if (res) {
        setFormData({ name: "" });
        setIsLoading(false);
        toast.success("Tag Created");
        if (refreshState) {
          handleClose();
          refreshState();
        }
      }
    } catch (err) {
      console.log(err);
      toast.info(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   const fetchTags = async () => {
  //     setIsLoading(true);
  //     try {
  //       const res = await api.getAllTags();
  //       setAllTag(res?.data || []);
  //       setIsLoading(false);
  //     } catch (err) {
  //       setIsLoading(false);

  //       console.log(err);
  //     }
  //   };
  //   fetchTags();
  // }, [refresh]);

  // const deleteTag = async (id) => {
  //   setIsLoading(true);
  //   try {
  //     const res = await api.deleteTag(id);
  //     if (res) {
  //       toast.success("Tag Deleted Successfully");
  //       setRefresh(!refresh);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);

  //     console.log(error);
  //     if (error.response.data.message) {
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const updateTag = async () => {
    setIsLoading(true);
    try {
      const res = await api.updateTag(data?.id, formData);

      if (res) {
        setFormData({ name: "" });
        setIsLoading(false);
        toast.success("Tag Updated");
        setRefresh(!refresh);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className=" fixed inset-0 bg-black/70 z-50 flex justify-center items-center">
        <div className=" relative w-[300px] md:-[450px] h-36 bg-white text-center rounded-md px-4 py-2">
          <h1 className="font-bold">{title}</h1>
          <div className="py-4">
            <input
              name="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="focus:outline-none border border-black rounded p-1 w-full"
            />
          </div>
          <div className="flex justify-between">
            <SubmitButton
              functions={() => {
                CreateTag();
              }}
            />
            <SubmitButton functions={() => handleClose()} name="Cancel" />
          </div>
          {/* <div className="flex justify-between">
            <button
              onClick={Cancel}
              className="border border-black rounded-md px-6 py-1"
            >
              Cancel
            </button>
            <button
              onClick={click}
              className="bg-darkblue text-white rounded-md px-6 py-1"
            >
              {buttonName}
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default TagForm;
