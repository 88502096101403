import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";
import ContactCardSkeleton from "../../components/cards/ContactCardSkeleton";
import ContactCard from "../../components/cards/ContactCard";
import { CiFilter } from "react-icons/ci";
import { getContacts } from "../../components/utils/api/apiClient";

function Contacts() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dummyData = [1, 2, 3, 4, 5];

  useEffect(() => {
    const getContact = async () => {
      setLoading(true);
      try {
        const { data } = await getContacts();
        if (data) {
          setData(data?.contact_us_forms);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getContact();
  }, []);

  return (
    <Layout>
      {loading && <Loading />}
      <div className="border border-lightgreen rounded">
        <div className="flex justify-between items-center p-3 border-b border-lightgreen">
          <h1 className="text-xl font-semibold">All Contacts</h1>
          <h4 className="text-lg font-semibold flex gap-1 items-center">
            <CiFilter />
            Filters
          </h4>
        </div>
        <div className="flex flex-wrap gap-4 p-3">
          {data.length > 0 ? (
            data?.map((item, index) => <ContactCard key={index} data={item} />)
          ) : (
            <>
              {dummyData?.map((_, index) => (
                <ContactCardSkeleton key={index} />
              ))}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Contacts;
