import React, { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import SubmitButton from "../buttons/SubmitButton";
import * as api from "../utils/api/apiClient";

function FaqForm({ pageRefresh, whatDoYouWant = "create", data }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const addFaq = async () => {
    setLoading(true);
    try {
      const { data } = await api.addFaq(formData);
      if (data) {
        setFormData({
          question: "",
          answer: "",
        });
        pageRefresh();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const editFaq = async () => {
    setLoading(true);
    try {
      const res = await api.editFaq(data.id, formData);
      if (res.data) {
        setFormData({
          question: "",
          answer: "",
        });
        pageRefresh();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        question: data?.question,
        answer: data?.answer,
      });
    }
  }, [data]);

  return (
    <div>
      {loading && <Loading />}
      <div>
        <div className="mb-4">
          <label className="block mb-2">Question</label>
          <input
            required
            type="text"
            value={formData?.question}
            className="w-full p-2 border border-border rounded-lg"
            onChange={(e) =>
              setFormData({ ...formData, question: e.target.value })
            }
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Answer</label>
          <textarea
            rows="4"
            cols="50"
            required
            type="text"
            value={formData?.answer}
            className="w-full p-2 border border-border rounded-lg resize-none"
            onChange={(e) =>
              setFormData({ ...formData, answer: e.target.value })
            }
          />
        </div>
        <SubmitButton
          name={whatDoYouWant === "create" ? "Submit" : "Save"}
          disabled={
            formData.question?.length > 0 && formData.answer?.length > 0
              ? false
              : true
          }
          functions={() => {
            if (whatDoYouWant === "create") {
              addFaq();
            } else {
              editFaq();
            }
          }}
        />
      </div>
    </div>
  );
}

export default FaqForm;
