import React, { useContext, useEffect, useState } from "react";
import { getCookie } from "../../app/CookieConfig";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { IoClose, IoMenu } from "react-icons/io5";
import { AuthContext } from "../context/AuthContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { isLogin } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [cookie, setCookie] = useState("");

  useEffect(() => {
    const token = getCookie("access_token");
    setCookie(token);
  }, []);

  return (
    <div className="sticky top-0 z-30 border-b bg-white">
      <div className="flex justify-between items-center h-[60px] px-3">
        <Link to="/" className="font-semibold text-lg flex items-center gap-2">
          <img src="/logo.webp" alt="logo" className="w-8 h-8" />
          <span>Inflection Admin </span>
        </Link>
        <div className="flex items-center gap-2">
          <div>
            {!isLogin && (
              <Button
                variant="contained"
                disableElevation
                onClick={(event) => {
                  navigate("/auth/login");
                }}
              >
                Login
              </Button>
            )}
          </div>
          {isLogin && (
            <div className="sm:hidden text-2xl">
              {!isOpen ? (
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <IoMenu />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <IoClose />
                </button>
              )}
            </div>
          )}
        </div>
        {/* For Mobile Device */}
        <div className="sm:hidden absolute top-16 left-0 h-screen">
          {isOpen && (
            <Sidebar CloseDrawer={() => setIsOpen(false)} isCss={true}>
              <div
                onClick={() => setIsOpen(false)}
                className="bg-black bg-opacity-70 inset-0 absolute w-screen h-screen -z-10"
              ></div>
            </Sidebar>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
