import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "../src/pages/not-found/index";
import Home from "./pages/home/index";
import Blogs from "./pages/blogs/index";
import AddCategoryPage from "./pages/category";
import OneBlog from "./pages/blogs/blog_details";
import Gallery from "./pages/gallery";
import User from "./pages/user";
import Tags from "./pages/tags";
import Login from "./pages/login";
import ProtectedRoute from "./components/protected/ProtectedRoute";
import { getCookie, setCookie } from "./app/CookieConfig";
import { instance, setFormToken, setToken } from "./app/AxiosConfig";
import Contacts from "./pages/Contacts";
import Videos from "./pages/videos";
import Faq from "./pages/faq";
import Navbar from "./components/app/Navbar";
import { AuthContext } from "./components/context/AuthContext";
import CreateBlog from "./pages/blogs/create_blog";
import AddTagsToBlog from "./pages/blogs/create_blog/add_tags";

export default function App() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(() => {
    const access_token = getCookie("access_token");
    if (access_token == null || access_token.length <= 0) {
      return false;
    }
    return true;
  });

  const renewToken = async () => {
    try {
      const { data } = await instance.get("/users/renew-token");
      const access_token = data.access_token;
      const accessTokenExpiresAt = Date.parse(data.access_token_expires_at);
      const currentMilies = Date.now();
      setCookie(
        "access_token",
        access_token,
        parseInt((accessTokenExpiresAt - currentMilies) / 1000)
      );
      setCookie("access_token", access_token);
      setFormToken("access_token");
      setToken("access_token");
      setIsLogin(true);
    } catch (err) {
      console.log(err);
      setIsLogin(false);
    }
  };

  useEffect(() => {
    const accessToken = getCookie("access_token");
    if (accessToken && accessToken !== null) {
      setToken("access_token");
      setFormToken("access_token");
      setIsLogin(true);
    }
  }, []);

  useEffect(() => {
    const checkLogin = () => {
      const accessToken = getCookie("access_token");
      const refreshToken = getCookie("refresh_token");

      if (accessToken || accessToken !== null) {
        return;
      }

      if (refreshToken && refreshToken !== null) {
        setToken("refresh_token");
        renewToken();
        return;
      } else {
        if (isLogin) {
          navigate(0);
        }
      }
    };

    const loginInterval = setInterval(checkLogin, 1000);
    return () => clearInterval(loginInterval);
  }, [isLogin, navigate]);

  return (
    <AuthContext.Provider value={{ isLogin, setIsLogin }}>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<OneBlog />} />
          <Route path="/add_category" element={<AddCategoryPage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/users" element={<User />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/faq" element={<Faq />} />
          {/* Blogs Create */}
          <Route path="/blog_create" element={<CreateBlog />} />
          <Route path="/blog_add_tags/:id" element={<AddTagsToBlog />} />
          {/* unknown route */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AuthContext.Provider>
  );
}
