import React from 'react';

const MyModal = ({
  Title,
  Inputvalue,
  setInputvalue,
  Cancel,
  click,
  buttonName,
}) => {
  return (
    <>
      <div className=" fixed inset-0 bg-black/70 z-50 flex justify-center items-center">
        <div className=" relative w-[300px] md:-[450px] h-36 bg-white text-center rounded-md px-4 py-2">
          <h1 className="font-bold">{Title}</h1>
          <div className="py-4">
            <input
              value={Inputvalue}
              onChange={(e) => setInputvalue(e.target.value)}
              className="focus:outline-none border border-black rounded p-1 w-full"
            />
          </div>
          <div className="flex justify-between">
            <button
              onClick={Cancel}
              className="border border-black rounded-md px-6 py-1"
            >
              Cancel
            </button>
            <button
              onClick={click}
              className="bg-darkblue text-white rounded-md px-6 py-1"
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyModal;
