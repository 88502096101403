import React from "react";

function YoutubeVideoCard(props) {
  const videoId = props.videoLink.split("v=")[1];
  const embedLink = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="aspect-video">
      <iframe
        className="w-full h-full"
        src={embedLink}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default YoutubeVideoCard;
