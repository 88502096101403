import React, { useEffect, useState } from "react";
import * as api from "../../components/utils/api/apiClient";
import Loading from "../loading/Loading";
import { toast } from "react-toastify";
import SubmitButton from "../buttons/SubmitButton";

function CategoryForm({
  title,
  pageRefresh,
  data,
  whatDoYouWant = "create",
  closePopup,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  useEffect(() => {
    // TODO: if update category than use data to set formData and update function call
  }, []);

  const createCategory = async () => {
    setIsLoading(true);
    try {
      const res = await api.createCategory(formData);
      if (res) {
        setFormData({ name: "" });
        setIsLoading(false);
        toast.success("Category Created");
        if (pageRefresh) {
          pageRefresh();
          closePopup();
        }
      }
    } catch (err) {
      console.log(err);
      toast.info(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className=" fixed inset-0 bg-black/70 z-50 flex justify-center items-center">
        <div className=" relative w-[300px] md:-[450px] h-36 bg-white text-center rounded-md px-4 py-2">
          <h1 className="font-semibold">{title}</h1>
          <div className="py-4">
            <input
              name="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="focus:outline-none border border-black rounded p-1 w-full"
            />
          </div>
          <div className="flex justify-between">
            <SubmitButton
              functions={() => {
                createCategory();
              }}
            />
            <SubmitButton functions={() => closePopup()} name="Cancel" />
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryForm;
