import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { instance } from "../../app/AxiosConfig";
import DeletePopup from "../../components/deletepop/DeletePopup";
import MyModal from "../../components/mymodel/MyModel";
import HoverButton from "../../components/hoverbutton/HoverButton";
import Layout from "../../components/layout/Layout";
import * as api from "../../components/utils/api/apiClient";

const AddCategoryPage = () => {
  const [category, setCategory] = useState("");
  const [show, setShow] = useState(false);
  const [allcategory, setAllCategory] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPopupValue, setEditPopupValue] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addcategory = async () => {
    setIsLoading(true);
    try {
      const res = await instance.post("/categories/create", { name: category });
      if (res) {
        setCategory("");
        setIsLoading(false);
        toast.success("Category Created");
        fetchCategory();
      }
    } catch (err) {
      console.log(err);
      toast.info(err?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const fetchCategory = async () => {
    setIsLoading(true);
    try {
      const res = await api.getAllCategories();
      setAllCategory(res?.data || []);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const deletecategory = async () => {
    setIsLoading(true);
    try {
      const res = await instance.delete(
        `/categories/delete/${selectedCategoryId}`
      );
      if (res) {
        setShowDeletePopup(false);
        setIsLoading(true);
        fetchCategory();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const updatecategory = async () => {
    setIsLoading(true);
    try {
      const res = await instance.patch(
        `/categories/update/${selectedCategoryId}`,
        { name: editPopupValue }
      );
      if (res) {
        setIsLoading(false);
        setShowEditPopup(false);
        fetchCategory();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <div>
        {showDeletePopup && (
          <DeletePopup
            Title="Are you sure you want to delete?"
            Cancel={() => setShowDeletePopup(false)}
            Delete={deletecategory}
          />
        )}
        {showEditPopup && (
          <MyModal
            setInputvalue={(val) => setEditPopupValue(val)}
            Inputvalue={editPopupValue}
            Title="Update the Category Name"
            Cancel={() => setShowEditPopup(false)}
            buttonName="Update"
            click={updatecategory}
          />
        )}
        {/********* Add Categories **********/}
        <div className="inline md:flex justify-between items-center">
          <div className="text-3xl font-extrabold tracking-widest pb-3 md:pb-0">
            ALL&nbsp;<span className="text-olivegreen">CATEGORIES</span>
          </div>
          <button
            onClick={() => setShow(!show)}
            className="bg-darkblue hover:bg-white hover:text-blue border-2 border-blue px-12 py-2 rounded-md cursor-pointer"
          >
            ADD CATEGORY
          </button>
        </div>
        {show && (
          <MyModal
            setInputvalue={(val) => setCategory(val)}
            Inputvalue={category}
            Title="Add The Category Name"
            Cancel={() => setShow(!show)}
            buttonName="Add"
            click={() => {
              addcategory();
              setShow(!show);
            }}
          />
        )}

        {/********** All Categories  *******/}
        <div className="py-6">
          {allcategory &&
            allcategory.map((e, i) => {
              return (
                <div key={i}>
                  <HoverButton
                    Name={e.name}
                    openDeletePopup={() => {
                      setShowDeletePopup(true);
                      setSelectedCategoryId(e.id);
                    }}
                    openEditPopup={() => {
                      setShowEditPopup(true);
                      setEditPopupValue(e.name);
                      setSelectedCategoryId(e.id);
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default AddCategoryPage;
