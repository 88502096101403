import React, { useState, useEffect } from "react";

import { instance } from "../../../app/AxiosConfig";
import { useParams } from "react-router-dom";
import Layout from "../../../components/layout/Layout";

const OneBlog = () => {
  const [blogdata, setBlogData] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const getblog = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/posts/post/${id}`);
        setBlogData(res?.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    getblog();
  }, [id]);

  return (
    <Layout>
      <div className="w-full">
        {loading ? (
          <div>loading....</div>
        ) : (
          <div className="shadow-md space-y-4 px-2 md:px-8 py-3">
            <button className="bg-darkblue text-white rounded text-sm px-3 py-1 uppercase">
              {blogdata.category_name}
            </button>
            <h1 className="capitalize text-3xl md:text-4xl font-bold">
              {blogdata.title}
            </h1>
            <div>
              <img
                src={blogdata.thumbnail}
                width={900}
                height={900}
                alt="pic"
                className="w-full h-96 md:h-[550px] rounded-md"
              />
            </div>
            <div className="space-y-4">
              <h2 className="text-md font-semibold">{`Keywords: ${blogdata.meta_keywords}`}</h2>
              <h3 className="text-lg font-bold">{`Description: ${blogdata.meta_description}`}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: blogdata.content }}
                className="text-xl"
              ></div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OneBlog;
